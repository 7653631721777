<template>
  <div class="card h-100">
    <div class="card-body h-100 text-left">
      <h5 class="card-title">
        Documents
        <span class="link-text" @click="handleUpload">upload document</span>
      </h5>

      <div class="row">
        <div class="col-12">
          <LoadingSection :loading-state="file == null ? '' : file"></LoadingSection>
        </div>
        <div v-if="file?.length == 0" class="col-12">
          No files available
        </div>
        <div v-else class="col-12">
          <table class="table table-hover text-left">
            <tbody>
            <tr v-for="(item, index) in file" :key="index">
              <td class="pr-0" scope="row">
                <icon v-if="
                      item.ext == 'png' ||
                      item.ext == 'jpg' ||
                      item.ext == 'jpeg'
                    " :data="image" color="blue" dir="up" height="25" width="25"/>
                <icon v-if="item.ext == 'pdf'" :data="pdf" color="red" dir="up" height="25" width="25"/>
              </td>
              <td>{{ fileName(item) }}</td>
              <td class="text-right" style="width:120px;">
                <button v-if="item.type == 'lor' && (getUserPermissions).includes('can-delete-file')"
                        class="btn btn-sm btn-danger mr-1"
                        @click="handleDelete(item)">
                  <icon :data="remove" dir="up"/>
                </button>
                <button class="btn btn-sm btn-success" @click="handleClick(item.type, item.link)">
                  <icon :data="download" dir="up"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import pdf from "@fa/regular/file-pdf.svg";
import download from "@fa/solid/download.svg";
import image from "@fa/regular/file-image.svg";
import remove from "@fa/solid/times.svg";
import LoadingSection from "@/components/loader/LoadingSection";
import {watchViewport} from "@/helpers/viewportWatcher";
// import logger from "pusher-js/src/core/logger";

export default {
  name: "Documents",
  props: {
    // file: {
    //   default: null
    // }
  },
  data: () => ({
    pdf,
    image,
    download,
    remove
  }),
  components: {
    LoadingSection
  },
  computed: {
    ...mapGetters([
      "getApiUrl",
      "getApiUrl",
      "getUserPermissions",
      "getFiles",
      "getApplicationUuid",
      "getTracedPensionUuid"
    ]),
    file() {
      return this.getFiles;
    }
  },
  methods: {
    ...mapActions([
      "deleteFile",
      "getDocuments",
      "fetchUserPermissions"
    ]),
    handleUpload() {
      this.$router.push({
        name: "File Upload",
        query: {
          auuid: this.$route.params.uuid,
          tpuuid: this.$route.params.tpuuid
        }
      });
    },
    btnText(type) {
      let btnText = null;

      switch (type) {
        case "ar":
        case "loa":
        case "loa_kp":
        case "loa_kp_ns":
        case "loa_ukp":
        case "loa_ukp_ns":
        case "lor":
        case "additional_info":
        case "loa_rejection":
        case "customer_chasing":
        case "op_sent_lor":
        case "op_cannot_find_policy":
        case "op_policy_doesnt_exist":
        case "wet_signature_loa":
        case "complaint":
        case "other":
          btnText = "Download";
          break;
      }
      return btnText;
    },
    fileName({source, type}) {
      console.log(source);
      console.log(type);
      let fileName = null;
      let provider = "";
      source !== null ? provider = ' - ' + source : provider = "";
      switch (type) {
        case "ar":
          fileName = "Pension Report";
          break;
        case "loa":
          fileName = "LOA" + provider;
          break;
        case "loa_kp":
          fileName = "LOA" + provider;
          break;
        case "loa_kp_ns":
          fileName = "LOA - No Signature" + provider;
          break;
        case "loa_ukp":
          fileName = "LOA - Unknown Policy Number" + provider;
          break;
        case "loa_ukp_ns":
          fileName =
              "LOA - Unknown Policy Number - No Signature" + provider;
          break;
        case "lor":
          fileName = "Letter of Response";
          break;
        case "additional_info":
          fileName = "Additional Info";
          break;
        case "loa_rejection":
          fileName = "LOA rejection";
          break;
        case "customer_chasing":
          fileName = "Customer chasing";
          break;
        case "op_sent_lor":
          fileName = "OP sent LOR to customer confirmation";
          break;
        case "op_cannot_find_policy":
          fileName = "OP cannot find policy";
          break;
        case "op_policy_doesnt_exist":
          fileName = "OP policy doesn't exist";
          break;
        case "wet_signature_loa":
          fileName = "Wet Signature LOA";
          break;
        case "complaint":
          fileName = "Complaint";
          break;
        case "other":
          fileName = "Other";
          break;
        default:
          fileName = type;
      }
      return fileName;
    },
    handleView(link) {
      this.forceFileDownload(link);
    },
    handleEdit() {
      this.$router.push({
        name: "Pension Form",
        params: {
          type: "aggregation-edit",
          auuid: this.$route.params.uuid,
          tpuuid: this.$route.params.tpuuid
        }
      });
    },
    async handleDelete(item) {
      if (confirm("Are you sure you want to delete this " + item.type + "?")) {
        await this.deleteFile({
          type: item.type, // signature | cv
          auuid: this.$route.params.uuid,
          tpuuid: this.$route.params.tpuuid,
          ext: item.ext,
        }).catch(err => console.log(err))

        this.$parent.getFiles();
      }
    },
    handleClick(type, link = null) {
      this.forceFileDownload(link);
    },
    forceFileDownload(response) {
      const link = document.createElement("a");
      link.href = response;
      link.target = "_blank";
      link.setAttribute("download", "file_download");
      link.click();
    }
  },
  async mounted() {
    await this.fetchUserPermissions();
  }
};
</script>
